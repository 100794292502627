body {
  font-family: "Roboto", sans-serif;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  font-size: 14px;
  letter-spacing: -.3px;
  background-color: #FFF;
  color: #777;
  font-weight: 400;
  line-height: 1.667
}

.MuiTableCell-root {
  font-size: 0.9rem !important;
}